<template>
    <div>
        <b-alert variant="danger" :show="userData === undefined">
            <h4 class="alert-heading">
                {{ $t('Error fetching user data') }}
            </h4>
            <div class="alert-body">
                {{ $t('No user found with this user id. Check') }}
                <b-link class="alert-link" :to="{ name: 'apps-users-list' }">
                    {{ $t('User List') }}
                </b-link>
                {{ $t('for other users.') }}
            </div>
        </b-alert>

        <b-card>
            <b-col cols="12" xl="12" class="d-flex justify-content-between flex-column">
                <div class="d-flex justify-content-start">
                    <div v-if="userData && userData.photo">
                        <b-avatar :src="userData.photo" size="104px" rounded />
                    </div>
                    <div v-else-if="userData && userData.fullname">
                        <b-avatar :text="avatarText(userData.fullname)" size="104px" rounded />
                    </div>
                    <div v-else>
                        <b-avatar :text="avatarText('N/A')" size="104px" rounded />
                    </div>
                    <div class="d-flex flex-column ml-1">
                        <div class="d-flex flex-wrap">
                            <b-media-body class="mt-75 ml-75">
                                <div v-if="userData && userData.photo">
                                    <b-button variant="danger" size="sm" class="mb-75 mr-75" :state="Boolean(file)"
                                        v-model="file" @click="deleteFile()">
                                        {{ $t('Delete') }}
                                    </b-button>
                                </div>
                                <div v-else>
                                    <b-button variant="primary" size="sm" class="mb-75 mr-75" :state="Boolean(file)"
                                        v-model="file" @click="$refs.refInputEl.$el.click()">
                                        {{ $t("Upload") }}
                                    </b-button>
                                </div>
                                <b-form-file ref="refInputEl" v-model="profileFile" accept=".jpg, .png, .gif"
                                    :hidden="true" plain @input="fileSelected" />
                                <b-card-text>{{ $t("Allowed JPG, GIF or PNG") }}</b-card-text>
                            </b-media-body>

                        </div>

                    </div>

                </div>
                <div v-if="userData" class="mt-3">
                    <h5>{{ $t('User Name') }}</h5>
                    <b-form-group>
                        <b-form-input v-model="userData.username" />
                    </b-form-group>
                    <h5>{{ $t('Full Name') }}</h5>
                    <b-form-group>
                        <b-form-input v-model="userData.fullname" />
                    </b-form-group>
                    <h5>{{ $t('Email') }}</h5>
                    <b-form-group>
                        <b-form-input v-model="userData.email" />
                    </b-form-group>
                    <h5>{{ $t('Mobile') }}</h5>
                    <b-form-group>
                        <b-form-input v-model="userData.mobile" type="number" />
                    </b-form-group>
                    <h5>{{ $t('Date of Birth') }}</h5>
                    <b-form-group>
                        <b-form-datepicker v-model="userData.birthDate" :show-decade-nav="true" :hide-header="true"
                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" />
                    </b-form-group>
                    <h5>{{ $t('Select Department') }}</h5>
                    <department-picker class="col-12 " :initialDepartment="userData.platformdepartmentname"
                        @selectionChanged="selectionChanged">
                    </department-picker>

                </div>
                <div v-if="userData" class="mt-2 d-flex justify-content-end">
                    <b-button variant="primary" @click="updateUser()">{{ $t('Save Changes') }}</b-button>
                </div>
            </b-col>
        </b-card>
    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter';
import axios from "@axios";
import userStoreModule from '../memberStoreModule';
import DepartmentPicker from "@/views/department/components/DepartmentPicker.vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {
    BCard, BButton, BAvatar, BRow, BCol, BAlert, BLink, BFormGroup, BFormInput, BFormFile, BMediaBody, BCardText, BFormDatepicker
} from 'bootstrap-vue'
export default {
    components: {
        BFormFile,
        BButton,
        BRow,
        BCol,
        BAlert,
        BLink,
        BAvatar,
        BCard,
        BFormGroup,
        BFormInput,
        DepartmentPicker,
        BMediaBody,
        BCardText,
        BFormDatepicker
    },
    data() {
        return {
            file: null,
            profileFile: null,
            userData: null
        };
    },
    created() {
        const USER_APP_STORE_MODULE_NAME = 'app-member-edit-page';

        // Register module
        if (!this.$store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            this.$store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule);
        }

        // Fetch user data
        const userId = this.$route.params.id;
        this.fetchUserData(userId);
    },
    beforeDestroy() {
        const USER_APP_STORE_MODULE_NAME = 'app-member-edit-page';

        // Unregister module
        if (this.$store.hasModule(USER_APP_STORE_MODULE_NAME)) {
            this.$store.unregisterModule(USER_APP_STORE_MODULE_NAME);
        }
    },
    methods: {
        findBucketName() {
            return this.userData.photo.split('/').pop()
        },
        deleteFile() {
            if (this.userData.photo.includes('storage.googleapis.com')) {
                axios({
                method: "delete",
                url: `/upload/${this.findBucketName()}`,
            })
                .then((r) => {
                    this.userData.photo = null;
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Success`,
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((r) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Error`,
                            icon: "AlertOctagonIcon",
                            variant: "warning",
                        },
                    });
                }); 
            }
            else{
                this.userData.photo = null;
            }
            
        },
        fileSelected(payload) {
            const formData = new FormData();
            formData.append("file", payload);
            formData.append("courseId", this.courseId);
            formData.append("acc", 3);
            formData.append("platform", "webadmin");
            this.isLoading = true;

            axios
                .post("/upload/admin", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                })
                .then((response) => {
                    //console.log(response);
                    this.userData.photo = response.data.url;
                    this.isUpdated = true;
                    this.isLoading = false;
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Success`,
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch((r) => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Error`,
                            icon: "AlertOctagonIcon",
                            variant: "warning",
                        },
                    });
                }); 
        },
        selectionChanged(payload) {
            this.userData.platformdepartmentname = payload.name
            this.userData.platformdepartmentid = payload.departmentplatformid
        },
        fetchUserData(userId) {
            this.$store.dispatch('app-member-edit-page/fetchUser', { id: userId })
                .then(response => {
                    this.userData = response.data.users;
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    if (error.response && error.response.status === 404) {
                        this.userData = undefined;
                    }
                });
        },
        updateUser() {

            this.$store.dispatch('app-member-edit-page/updateUser', this.userData)
                .then(response => {
                    this.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                            title: `Success`,
                            icon: "CheckCircleIcon",
                            variant: "success",
                        },
                    });
                })
                .catch(error => {
                    console.error('Error fetching user data:', error);
                    if (error.response && error.response.status === 404) {
                        this.userData = undefined;
                    }
                });
        }
    },
    setup() {
        return {
            avatarText,
        }
    },
};
</script>